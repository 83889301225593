

.prenotazioniContainer{
    width: 70%;
    margin: 5em auto;
}

.prenotazioniContainer h2{
    font-size: 2.5em;
    font-weight: 700;
    color: #002F32;
    margin-bottom: 2.5em;
}

.noPrenotation{
    color: #002F32;
    font-size: 1.2em;
    font-weight: 600;
    margin-left: 0.5em;
    width: auto;
    margin-bottom: 1.5em;
}

.sediContainer p{
    color: #002F32;
    font-size: 1.2em;
    font-weight: 600;
    margin-left: 0.5em;
    text-transform: capitalize;
    width: auto;
    margin-bottom: 1.5em;
}

.userContainer{
    display: flex;
    flex-direction: row;
}

.userContainer p{
    font-size: 1.2em;
    font-weight: 300;
}