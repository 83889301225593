
.oreContainer{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    padding-top: 2em;
    background: #fff;
    border-radius: 2em;
}

.chooseHourContainer{
    overflow: hidden;
    background: url('../static/imgSfondoOre3.png') no-repeat;
    background-position: -20% -50%;
    border-radius: 1em;
}

.chooseHour {
 /*    display: grid; */
    position: fixed;
    left: 60%;
    width: 25%;
    background: #fff;
    height: 24em;
    border-radius: 2em;
    margin-top: 14em;
    padding: 0 0 1em;
}

.initialImg{
    width: 100%;
    border-radius: 2em;
    margin: 1.5em 10em;
    visibility: hidden;
}

.iconCheckContainer{
    background: #fff;
    height: 100%;
    padding-top: 3em;
}

.showBg{
    position: fixed;
    z-index: -10;
    left: 50%;
    width: 30%;
    height: 20em;
}

.showBg img{
    width: 180%;
    opacity: 0.4;
}

.chooseHour .hour {
    border: 0;
    background: #dce2e1;
    padding: 1em 1em;
    color: #002F32;
    font-weight: bold;
    border-radius: 1em;
    font-family: 'Quicksand';
    align-self: center;
    margin: 1em auto 1em;
    font-size: .8em;
    flex-direction: column;
}

.counting{
    font-size: 1.1em;
}

.iconCounter{
    font-size: .9em;
    margin-left: .3em;
}

.time{
    text-align: center;
    font-size: 1.3em;
    margin-top: .5em;
}

.chooseHourContainer h3 {
    font-size: 1.2em;
    width: 80%;
    padding: 1em;
    margin: 1em 1em 0;
    text-align: center;
    border-bottom: 1px solid #dce2e1;
}

.iconOk{
    margin: 4em auto 1em;
    color: #59BA89;
}

.iconBad{
    margin: 4em auto 1em;
    color: #e22525;
}

.askPrenotation, .askDeletion{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #fff;
    height: 100%;
}

@media screen 
    and (min-device-width: 1200px) 
    and (max-device-width: 1600px) 
    and (-webkit-min-device-pixel-ratio: 1) { 

    .chooseHourContainer{
        background-position: 5% -52%;
    }

    .chooseHour{
        bottom: 5%;
    }

    .oreContainer{
        padding-top: 0;
    }
}

@media (max-width: 767px) {

    .showBg{
        top: 25%;
        left: 8%;
        width: 100%;
    }

    .chooseHourMobile{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .chooseHourContainerMobile{
        background: url('../static/imgSfondoOre3.png'), #dce2e1;
        background-repeat: no-repeat;
        background-position: 50% 100%;
        background-size: contain;
        width: 100%;
    }

    .chooseHourMobile h3{
        width: 75%;
        padding: 1em;
        font-size: 1.2em;
        font-weight: 600;
    }

    .oreContainer{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 90%;
        height: fit-content;
        margin: 0 auto;
        padding-top: 0;
        background: none;
    }

    .hour{
        border: 0;
        background: #fff;
        padding: 1.5em 1.8em;
        color: #002F32;
        font-weight: bold;
        border-radius: 1em;
        font-family: 'Quicksand';
        align-self: center;
        margin: 1em auto 1em;
        font-size: .8em;
        flex-direction: column;
    }

    .askPrenotation, .askDeletion{
        height: 10em;
        margin: 0 auto;
        border-radius: 2em;
        width: 90%;
        align-items: center;
    }

    .iconOk, .iconBad{
        margin: 0 auto;
    }

    .iconCheckContainer{
        background: url('../static/imgSfondoOre3.png'), #dce2e1;
        background-repeat: no-repeat;
        background-position: 20% 50%;
        /* background-size: contain; */
        padding-top: 0;
    }

    .chooseHourContainerMobileHeader{
        padding: 1em;
        /* width: 100%; */
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .backArrow{
        width: 20%;
    }
}