
.filtraggioContainer{
    background: #fff;
    border-radius: 2em;
    background: #002F32;
    padding: .8em;
    margin-right: 1em;
    box-shadow: 6px 5px 25px 0px rgba(0,0,0,0.2);
}

.filtraggioContainer input{
    border: 0;
    border-radius: 1em;
    color:whitesmoke;
    background: #002F32;
}

.filtraggioContainer input::placeholder{
    color: whitesmoke;
    opacity: .7;
    font-weight: 700;
    text-transform: uppercase;
    font-size: .8em;
}

.filtraggioContainer input:focus{
    outline: none;
}

.searchIcon{
    align-self: center;
    color: whitesmoke;
}

@media (max-width: 767px) {

    .filtraggioContainer{
        background: #dce2e1;
        box-shadow: none;
        border-bottom: 1px solid #002F32;
        border-radius: 0;
    }

    .filtraggioContainer input{
        background: #dce2e1;
        color: #002F32;
    }

    .filtraggioContainer input::placeholder{
        color: #002F32;
        font-size: .9em;
    }

    .searchIcon{
        color: #002F32;
    }
}

@media (min-width: 1024px) {
    .filtraggioContainer input{
        width: 85%;
    }
}