

.headerWrapper{
    position: fixed;
    top: 0;
    width: 100%;
    background: #dce2e1;
    padding: 4em 1em;
}

.headerCenter{
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.textContainer{
    color: #002F32;
    display: flex;
}

.textContainer h1{
    font-size: 3em;
    font-weight: 700;
    /* color: rgb(130, 130, 130); */
}

.textContainer h2{
    font-size: 1.2em;
    font-weight: 300;
    /* color: rgb(134, 170, 170); */
}

.filtraggio{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.starFiltraggio{
    position: relative;
    stroke: gold;
    stroke-width: 2em;
}

.starFiltraggio:hover{
    cursor: pointer;
}

.calendarIcon, .bookIcon, .logoutIcon{
    margin-left: 1em;
    cursor: pointer;
    color: #002F32;
}

.score{
    background: #002F32;
    width: 12%;
    padding: .5em;
    margin: 0 0 0 1.5em;
    border-radius: 1.5em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* cursor: pointer; */
}

.score *{
    padding: 0 .3em;
}

.score p{
    color: #fff;
    width: 100%;
    font-size: 1em;
    margin-bottom: .5em;
    text-align: center;
}

.score img{
    width: 30%;
}

.score span{
    align-self: center;
    font-weight: 700;
    font-size: .8em;
    color: #fff;
}

.tooltip{
    position: absolute;
    top: 65%;
    right: 20.7%;
    background: #002F32;
    color: #fff;
    padding: .5em;
    font-variant: small-caps;
}

.tooltip:before{
    content:"";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #002F32 transparent;
    position: absolute;
    bottom:100%;
    left:50%;
    margin-left:-5px;
}


@media (max-width: 767px) {
    .headerWrapper{
        padding: 1.2em 1em;
        width: 92.5%;
    }

    .headerCenter{
        margin: 0;
        flex-wrap: wrap;
        width: 100%;
    }

    .textContainer{
        display: block;
    }

    .textContainer h1{
        font-size: 1.8em;
        padding: .2em 0 0 0;
    }

    .textContainer h2{
        font-size: 1em;
    }

    .score{
        position: static;
        background: #002F32;
        width: 40%;
        padding: .8em;
        margin: 1.5em auto 0;
        border-radius: 1.5em;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .score *{
        padding: 0 .3em;
    }

    .score p{
        color: #fff;
        width: 100%;
        font-size: .8em;
        margin-bottom: .5em;
        text-align: center;
    }

    .score img{
        width: 20%;
    }

    .score span{
        align-self: center;
        font-weight: 700;
        font-size: .6em;
        color: #fff;
    }

    .tooltip{
        display: none;
    }

    .tooltip::before{
        display: none;
    }

    .filtraggio{
        width: 100%;
        align-self: center;
        justify-content: center;
        margin-top: 1em;
    }

    .starFiltraggio{
        position: fixed;
        top: 5%;
        right: 11%;
    }

    .calendarIcon{
        position: fixed;
        top: 10%;
        right: 11.5%;
    }

    .bookIcon{
        position: fixed;
        top: 15%;
        right: 11.5%;
    }

    .logoutIcon{
        position: fixed;
        top: 20%;
        right: 11%;
    }
}