

/* .stanzaWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
} */

.sala{
    display: flex;
    flex-direction: row;
    box-shadow: 6px 5px 25px 0px rgba(0,0,0,0.2);
    margin-bottom: 2em;
    height: 20em;
    background: #fff;
    border-radius: 2em;
}

.sala:hover{
    box-shadow: 6px 6px 30px 0px rgba(0, 0, 0, 0.2);
}

.imgSala{
    width: 75%;
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
    background-position: center;
    background-color: rgb(228, 228, 228);
}

.stanzaBtns {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

.maxOccupancy{
    margin: 2em auto 0;
    font-size: 1.2em;
    font-variant: small-caps;
    display: flex;
    flex-direction: column;
}

.maxOccupancy span{
    font-size: .8em;
    align-self: center;
    margin-bottom: 1em;
}

.maxOccupancy p{
    font-size: .9em;
}

.buttonPrenota{
    border: 0;
    background: #dce2e1;
    padding: .8em;
    color: #002F32;
    font-weight: bold;
    border-radius: 1em;
    font-family: 'Quicksand';
    align-self: flex-end;
    margin: 0 auto 1em;
    font-size: .8em;
}


.bookmarkIcon {
    color: #002F32;
    align-self: flex-end;
}

.buttonPrenota:hover{
    cursor: pointer;
    background: #D8F0DE;
}

.buttonPrenota:hover .bookmarkIcon{
    stroke: whitesmoke;
    stroke-width: 5em;
}

@media (max-width: 767px) {

    .sala{
        height: 20em;
        border-radius: 1em;
        display: flex;
        flex-direction: column;
    }

    .imgSala{
        width: 100%;
        height: 90%;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        border-bottom-left-radius: 0;
        background-position: center;
    }

    .stanzaBtns{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 1em 0;
    }

    .maxOccupancy{
        align-self: center;
        margin: 0 auto;
        font-size: 1.3em;
    }

    .buttonPrenota{
        margin: 0 auto;
    }

}