
.dashboardPage{
    width: 70%;
    margin: 0 auto;
}

@media (max-width: 767px) {

    .dashboardPage{
        width: 90%;
    }
}