
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap');

.loginPage{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: url('../static/healthyfoodbg.jpg') no-repeat;
    background-size: cover;
}

.loginWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 30%;
    height: 100vh;
    align-self: center;
    /* background: #fff;
    border-radius: 2em; */
    background: rgba( 255, 255, 255, 0.35 );
    /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
    backdrop-filter: blur( 5px );
    -webkit-backdrop-filter: blur( 5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.imgLogoContainer{
    width: 50%;
    height: 50%;
    background: url('../static/logo_avr1.png') no-repeat;
    background-size: contain;
    align-self: center;
}

.buttonLogin{
    align-self: center; 
    border: 0;
    padding: 1em 1.5em;
    font-size: 1.1em;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
    border-radius: 1em;
    font-weight: 700;
    font-family: 'Quicksand';
}

.buttonLogin:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}


@media (max-width: 767px) {

    .buttonLogin{
        padding: 1em 1.5em;
        font-size: 1.1em;
    }

    .loginWrapper{
        padding: 1em;
        width: 60%;
        height: 25%;
        margin: 20% auto 0;
    }
    
    .imgLogoContainer{
        width: 80%;
        height: 80%;
    }
}

