@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
 */


body {
	position: relative;
	margin: 0;
/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Quicksand';
	background: #dce2e1;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.loginPage{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: url(/static/media/healthyfoodbg.361c747c.jpg) no-repeat;
    background-size: cover;
}

.loginWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 30%;
    height: 100vh;
    align-self: center;
    /* background: #fff;
    border-radius: 2em; */
    background: rgba( 255, 255, 255, 0.35 );
    /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
    backdrop-filter: blur( 5px );
    -webkit-backdrop-filter: blur( 5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.imgLogoContainer{
    width: 50%;
    height: 50%;
    background: url(/static/media/logo_avr1.1769fd3d.png) no-repeat;
    background-size: contain;
    align-self: center;
}

.buttonLogin{
    align-self: center; 
    border: 0;
    padding: 1em 1.5em;
    font-size: 1.1em;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
    border-radius: 1em;
    font-weight: 700;
    font-family: 'Quicksand';
}

.buttonLogin:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}


@media (max-width: 767px) {

    .buttonLogin{
        padding: 1em 1.5em;
        font-size: 1.1em;
    }

    .loginWrapper{
        padding: 1em;
        width: 60%;
        height: 25%;
        margin: 20% auto 0;
    }
    
    .imgLogoContainer{
        width: 80%;
        height: 80%;
    }
}



.dashboardPage{
    width: 70%;
    margin: 0 auto;
}

@media (max-width: 767px) {

    .dashboardPage{
        width: 90%;
    }
}


.headerWrapper{
    position: fixed;
    top: 0;
    width: 100%;
    background: #dce2e1;
    padding: 4em 1em;
}

.headerCenter{
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.textContainer{
    color: #002F32;
    display: flex;
}

.textContainer h1{
    font-size: 3em;
    font-weight: 700;
    /* color: rgb(130, 130, 130); */
}

.textContainer h2{
    font-size: 1.2em;
    font-weight: 300;
    /* color: rgb(134, 170, 170); */
}

.filtraggio{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.starFiltraggio{
    position: relative;
    stroke: gold;
    stroke-width: 2em;
}

.starFiltraggio:hover{
    cursor: pointer;
}

.calendarIcon, .bookIcon, .logoutIcon{
    margin-left: 1em;
    cursor: pointer;
    color: #002F32;
}

.score{
    background: #002F32;
    width: 12%;
    padding: .5em;
    margin: 0 0 0 1.5em;
    border-radius: 1.5em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* cursor: pointer; */
}

.score *{
    padding: 0 .3em;
}

.score p{
    color: #fff;
    width: 100%;
    font-size: 1em;
    margin-bottom: .5em;
    text-align: center;
}

.score img{
    width: 30%;
}

.score span{
    align-self: center;
    font-weight: 700;
    font-size: .8em;
    color: #fff;
}

.tooltip{
    position: absolute;
    top: 65%;
    right: 20.7%;
    background: #002F32;
    color: #fff;
    padding: .5em;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
}

.tooltip:before{
    content:"";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #002F32 transparent;
    position: absolute;
    bottom:100%;
    left:50%;
    margin-left:-5px;
}


@media (max-width: 767px) {
    .headerWrapper{
        padding: 1.2em 1em;
        width: 92.5%;
    }

    .headerCenter{
        margin: 0;
        flex-wrap: wrap;
        width: 100%;
    }

    .textContainer{
        display: block;
    }

    .textContainer h1{
        font-size: 1.8em;
        padding: .2em 0 0 0;
    }

    .textContainer h2{
        font-size: 1em;
    }

    .score{
        position: static;
        background: #002F32;
        width: 40%;
        padding: .8em;
        margin: 1.5em auto 0;
        border-radius: 1.5em;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .score *{
        padding: 0 .3em;
    }

    .score p{
        color: #fff;
        width: 100%;
        font-size: .8em;
        margin-bottom: .5em;
        text-align: center;
    }

    .score img{
        width: 20%;
    }

    .score span{
        align-self: center;
        font-weight: 700;
        font-size: .6em;
        color: #fff;
    }

    .tooltip{
        display: none;
    }

    .tooltip::before{
        display: none;
    }

    .filtraggio{
        width: 100%;
        align-self: center;
        justify-content: center;
        margin-top: 1em;
    }

    .starFiltraggio{
        position: fixed;
        top: 5%;
        right: 11%;
    }

    .calendarIcon{
        position: fixed;
        top: 10%;
        right: 11.5%;
    }

    .bookIcon{
        position: fixed;
        top: 15%;
        right: 11.5%;
    }

    .logoutIcon{
        position: fixed;
        top: 20%;
        right: 11%;
    }
}

.filtraggioContainer{
    background: #fff;
    border-radius: 2em;
    background: #002F32;
    padding: .8em;
    margin-right: 1em;
    box-shadow: 6px 5px 25px 0px rgba(0,0,0,0.2);
}

.filtraggioContainer input{
    border: 0;
    border-radius: 1em;
    color:whitesmoke;
    background: #002F32;
}

.filtraggioContainer input::-webkit-input-placeholder{
    color: whitesmoke;
    opacity: .7;
    font-weight: 700;
    text-transform: uppercase;
    font-size: .8em;
}

.filtraggioContainer input:-ms-input-placeholder{
    color: whitesmoke;
    opacity: .7;
    font-weight: 700;
    text-transform: uppercase;
    font-size: .8em;
}

.filtraggioContainer input::placeholder{
    color: whitesmoke;
    opacity: .7;
    font-weight: 700;
    text-transform: uppercase;
    font-size: .8em;
}

.filtraggioContainer input:focus{
    outline: none;
}

.searchIcon{
    align-self: center;
    color: whitesmoke;
}

@media (max-width: 767px) {

    .filtraggioContainer{
        background: #dce2e1;
        box-shadow: none;
        border-bottom: 1px solid #002F32;
        border-radius: 0;
    }

    .filtraggioContainer input{
        background: #dce2e1;
        color: #002F32;
    }

    .filtraggioContainer input::-webkit-input-placeholder{
        color: #002F32;
        font-size: .9em;
    }

    .filtraggioContainer input:-ms-input-placeholder{
        color: #002F32;
        font-size: .9em;
    }

    .filtraggioContainer input::placeholder{
        color: #002F32;
        font-size: .9em;
    }

    .searchIcon{
        color: #002F32;
    }
}

@media (min-width: 1024px) {
    .filtraggioContainer input{
        width: 85%;
    }
}

.sedeWrapper{
    display: flex;
    flex-direction: column;
}

.category{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    /* width: 5.2em; */
    margin: 3em 0 2em;
}

.category h3{
    color: #002F32;
    font-size: 1.2em;
    font-weight: 600;
    margin-left: .5em;
    text-transform: capitalize;
    width: auto;
}

/* solo di prova per poi cambiarlo con react*/
.favorites{
    /* color: rgb(130, 130, 130); */
    padding: .1em;
}

.favorites:hover{
    /* color: gold; */
    cursor: pointer;
}

.preferiti{
    display: flex;
}

.invisibili{
    display: none;
}




/* .stanzaWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
} */

.sala{
    display: flex;
    flex-direction: row;
    box-shadow: 6px 5px 25px 0px rgba(0,0,0,0.2);
    margin-bottom: 2em;
    height: 20em;
    background: #fff;
    border-radius: 2em;
}

.sala:hover{
    box-shadow: 6px 6px 30px 0px rgba(0, 0, 0, 0.2);
}

.imgSala{
    width: 75%;
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
    background-position: center;
    background-color: rgb(228, 228, 228);
}

.stanzaBtns {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

.maxOccupancy{
    margin: 2em auto 0;
    font-size: 1.2em;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    display: flex;
    flex-direction: column;
}

.maxOccupancy span{
    font-size: .8em;
    align-self: center;
    margin-bottom: 1em;
}

.maxOccupancy p{
    font-size: .9em;
}

.buttonPrenota{
    border: 0;
    background: #dce2e1;
    padding: .8em;
    color: #002F32;
    font-weight: bold;
    border-radius: 1em;
    font-family: 'Quicksand';
    align-self: flex-end;
    margin: 0 auto 1em;
    font-size: .8em;
}


.bookmarkIcon {
    color: #002F32;
    align-self: flex-end;
}

.buttonPrenota:hover{
    cursor: pointer;
    background: #D8F0DE;
}

.buttonPrenota:hover .bookmarkIcon{
    stroke: whitesmoke;
    stroke-width: 5em;
}

@media (max-width: 767px) {

    .sala{
        height: 20em;
        border-radius: 1em;
        display: flex;
        flex-direction: column;
    }

    .imgSala{
        width: 100%;
        height: 90%;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        border-bottom-left-radius: 0;
        background-position: center;
    }

    .stanzaBtns{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 1em 0;
    }

    .maxOccupancy{
        align-self: center;
        margin: 0 auto;
        font-size: 1.3em;
    }

    .buttonPrenota{
        margin: 0 auto;
    }

}

.reminderPrenotation{
    align-self: center;
    display: none;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 1em;
}

.abortText{
    font-size: .8em;
    /* font-weight: 600; */
    margin-bottom: 1em;
}

.checkCard{
    align-self: center;
    color: #59BA89;
    margin-bottom: .3em;
}

.orarioPrenotazione{
    margin-bottom: .3em;
    align-self: center;
    font-size: .8em;
}

.abortedCard{
    color: #e22525;
    align-self: center;
    cursor: pointer;
}

@media (max-width: 767px) {

    .reminderPrenotation{
        width: 50%;
        margin-bottom: 0;
    }

    .abortText{
        align-self: center;
    }
}

.oreContainer{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    padding-top: 2em;
    background: #fff;
    border-radius: 2em;
}

.chooseHourContainer{
    overflow: hidden;
    background: url(/static/media/imgSfondoOre3.a28b0e4b.png) no-repeat;
    background-position: -20% -50%;
    border-radius: 1em;
}

.chooseHour {
 /*    display: grid; */
    position: fixed;
    left: 60%;
    width: 25%;
    background: #fff;
    height: 24em;
    border-radius: 2em;
    margin-top: 14em;
    padding: 0 0 1em;
}

.initialImg{
    width: 100%;
    border-radius: 2em;
    margin: 1.5em 10em;
    visibility: hidden;
}

.iconCheckContainer{
    background: #fff;
    height: 100%;
    padding-top: 3em;
}

.showBg{
    position: fixed;
    z-index: -10;
    left: 50%;
    width: 30%;
    height: 20em;
}

.showBg img{
    width: 180%;
    opacity: 0.4;
}

.chooseHour .hour {
    border: 0;
    background: #dce2e1;
    padding: 1em 1em;
    color: #002F32;
    font-weight: bold;
    border-radius: 1em;
    font-family: 'Quicksand';
    align-self: center;
    margin: 1em auto 1em;
    font-size: .8em;
    flex-direction: column;
}

.counting{
    font-size: 1.1em;
}

.iconCounter{
    font-size: .9em;
    margin-left: .3em;
}

.time{
    text-align: center;
    font-size: 1.3em;
    margin-top: .5em;
}

.chooseHourContainer h3 {
    font-size: 1.2em;
    width: 80%;
    padding: 1em;
    margin: 1em 1em 0;
    text-align: center;
    border-bottom: 1px solid #dce2e1;
}

.iconOk{
    margin: 4em auto 1em;
    color: #59BA89;
}

.iconBad{
    margin: 4em auto 1em;
    color: #e22525;
}

.askPrenotation, .askDeletion{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #fff;
    height: 100%;
}

@media screen 
    and (min-device-width: 1200px) 
    and (max-device-width: 1600px) 
    and (-webkit-min-device-pixel-ratio: 1) { 

    .chooseHourContainer{
        background-position: 5% -52%;
    }

    .chooseHour{
        bottom: 5%;
    }

    .oreContainer{
        padding-top: 0;
    }
}

@media (max-width: 767px) {

    .showBg{
        top: 25%;
        left: 8%;
        width: 100%;
    }

    .chooseHourMobile{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .chooseHourContainerMobile{
        background: url(/static/media/imgSfondoOre3.a28b0e4b.png), #dce2e1;
        background-repeat: no-repeat;
        background-position: 50% 100%;
        background-size: contain;
        width: 100%;
    }

    .chooseHourMobile h3{
        width: 75%;
        padding: 1em;
        font-size: 1.2em;
        font-weight: 600;
    }

    .oreContainer{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 90%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: 0 auto;
        padding-top: 0;
        background: none;
    }

    .hour{
        border: 0;
        background: #fff;
        padding: 1.5em 1.8em;
        color: #002F32;
        font-weight: bold;
        border-radius: 1em;
        font-family: 'Quicksand';
        align-self: center;
        margin: 1em auto 1em;
        font-size: .8em;
        flex-direction: column;
    }

    .askPrenotation, .askDeletion{
        height: 10em;
        margin: 0 auto;
        border-radius: 2em;
        width: 90%;
        align-items: center;
    }

    .iconOk, .iconBad{
        margin: 0 auto;
    }

    .iconCheckContainer{
        background: url(/static/media/imgSfondoOre3.a28b0e4b.png), #dce2e1;
        background-repeat: no-repeat;
        background-position: 20% 50%;
        /* background-size: contain; */
        padding-top: 0;
    }

    .chooseHourContainerMobileHeader{
        padding: 1em;
        /* width: 100%; */
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .backArrow{
        width: 20%;
    }
}

.fullContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 13em;
    /* background: url('../static/imgLat.png') no-repeat;
    background-position: 70% 60%;
    background-attachment: fixed; */
}

.cardsContainer {
    width: 55%;
}

@media (max-width: 767px) {

    .cardsContainer{
        width: 100%;
    }
}



.prenotazioniContainer{
    width: 70%;
    margin: 5em auto;
}

.prenotazioniContainer h2{
    font-size: 2.5em;
    font-weight: 700;
    color: #002F32;
    margin-bottom: 2.5em;
}

.noPrenotation{
    color: #002F32;
    font-size: 1.2em;
    font-weight: 600;
    margin-left: 0.5em;
    width: auto;
    margin-bottom: 1.5em;
}

.sediContainer p{
    color: #002F32;
    font-size: 1.2em;
    font-weight: 600;
    margin-left: 0.5em;
    text-transform: capitalize;
    width: auto;
    margin-bottom: 1.5em;
}

.userContainer{
    display: flex;
    flex-direction: row;
}

.userContainer p{
    font-size: 1.2em;
    font-weight: 300;
}
