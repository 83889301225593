
.sedeWrapper{
    display: flex;
    flex-direction: column;
}

.category{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    /* width: 5.2em; */
    margin: 3em 0 2em;
}

.category h3{
    color: #002F32;
    font-size: 1.2em;
    font-weight: 600;
    margin-left: .5em;
    text-transform: capitalize;
    width: auto;
}

/* solo di prova per poi cambiarlo con react*/
.favorites{
    /* color: rgb(130, 130, 130); */
    padding: .1em;
}

.favorites:hover{
    /* color: gold; */
    cursor: pointer;
}

.preferiti{
    display: flex;
}

.invisibili{
    display: none;
}

