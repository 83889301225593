
.reminderPrenotation{
    align-self: center;
    display: none;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 1em;
}

.abortText{
    font-size: .8em;
    /* font-weight: 600; */
    margin-bottom: 1em;
}

.checkCard{
    align-self: center;
    color: #59BA89;
    margin-bottom: .3em;
}

.orarioPrenotazione{
    margin-bottom: .3em;
    align-self: center;
    font-size: .8em;
}

.abortedCard{
    color: #e22525;
    align-self: center;
    cursor: pointer;
}

@media (max-width: 767px) {

    .reminderPrenotation{
        width: 50%;
        margin-bottom: 0;
    }

    .abortText{
        align-self: center;
    }
}