
.fullContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 13em;
    /* background: url('../static/imgLat.png') no-repeat;
    background-position: 70% 60%;
    background-attachment: fixed; */
}

.cardsContainer {
    width: 55%;
}

@media (max-width: 767px) {

    .cardsContainer{
        width: 100%;
    }
}
